import React, { useEffect, useState } from "react";
import { ReactTerminal, TerminalContext } from "react-terminal";
import YouTube from "react-youtube";

const HackThisTerminalApplication = ({ setDragDisabled }) => {
  const [currentDirectory, setCurrentDirectory] = useState("/");
  const [decodedToken, setDecodedToken] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const { setBufferedContent, setTemporaryContent } =
    React.useContext(TerminalContext);
  const [videoShowing, setVideoShowing] = useState("ipM00VEtfW0");
  const [files, setFiles] = useState({
    "/": ["readme.txt", "home", "usr", "etc"],
    "/home": ["user1", "user2", "user3"],
    "/home/user1": ["documents", "pictures", "videos"],
    "/home/user1/documents": [
      "report.docx",
      "jwt_token1.enc",
      "meeting_notes.txt",
    ],
    "/home/user1/pictures": ["image.jpg", "holiday.png"],
    "/home/user1/videos": ["video1.mp4", "video2.mp4"],
    "/home/user2": ["documents", "downloads"],
    "/home/user2/documents": ["jwt_token2.enc", "project.pdf"],
    "/home/user2/downloads": ["software.tar.gz", "guide.pdf"],
    "/home/user3": ["music", "documents"],
    "/home/user3/documents": ["letter.docx", "notes.txt"],
    "/home/user3/music": ["song1.mp3", "playlist.m3u"],
    "/usr": ["bin", "lib", "local"],
    "/usr/bin": ["utilities"],
    "/usr/bin/utilities": ["tool.exe", "jwt_token3.enc"],
    "/usr/lib": ["library.so", "data.lib"],
    "/usr/local": ["config", "tmp"],
    "/usr/local/config": ["settings.conf", "network.conf", "script.sh"],
    "/usr/local/tmp": ["temp1.tmp", "temp2.tmp"],
    "/etc": ["config", "security"],
    "/etc/config": ["sysconfig.txt", "bootconfig.txt"],
    "/etc/security": ["hosts.allow", "hosts.deny"],
  });

  const base64Decode = (str) => {
    try {
      return atob(str);
    } catch (e) {
      return "Invalid Base64 string";
    }
  };

  const commands = {
    ls: (args) => {
      return files[currentDirectory].join("\n");
    },
    cd: (args) => {
      if (!args || args.length === 0) {
        return "No directory specified";
      }
      const newDir = args.trim();
      let targetDir = "";

      if (newDir === "..") {
        const pathParts = currentDirectory.split("/");
        pathParts.pop();
        targetDir = pathParts.join("/") || "/";
      } else if (newDir.startsWith("/")) {
        targetDir = newDir;
      } else {
        targetDir = `${currentDirectory}/${newDir}`.replace(/\/+/g, "/");
      }

      if (files[targetDir]) {
        setCurrentDirectory(targetDir);
        return targetDir;
      } else {
        return `No such directory: ${newDir}`;
      }
    },
    cat: (args) => {
      if (!args || args.length === 0) {
        return "No file specified";
      }
      const file = args.trim();
      if (file === "flag.txt") {
        setShowVideo(true);

        return "YOU HACKED ME NOOOOOOO";
      }
      if (currentDirectory === "/" && file === "readme.txt") {
        return "Imagine thinking youll find any tips in here lol";
      } else if (
        currentDirectory === "/home/user1/documents" &&
        file === "notes.txt"
      ) {
        return "You are getting closer.";
      } else if (file === "jwt_token3.enc") {
        return `${btoa('{"alg":"HS256","typ":"JWT"}')}.${btoa(
          '{"userId":"1","role":"admin","password":"plznohackme"}'
        )}.${btoa("signature")}`;
      } else if (file === "jwt_token1.enc") {
        return `${btoa('{"alg":"HS256","typ":"JWT"}')}.${btoa(
          '{"userId":"1","role":"user"'
        )}.${btoa("signature")}`;
      } else if (file === "jwt_token2.enc") {
        return `${btoa('{"alg":"HS256","typ":"JWT"}')}.${btoa(
          '{"userId":"3","role":"user"'
        )}.${btoa("signature")}`;
      } else if (
        decodedToken &&
        currentDirectory === "/usr/bin" &&
        file === "script.sh"
      ) {
        return "Congratulations! Now find the flag file.";
      } else if (
        currentDirectory === "/home/user2/documents" &&
        file === "work.txt"
      ) {
        return "Just some work notes.";
      } else if (file.endsWith(".conf")) {
        return `Content of ${file}: \n# Configuration settings for system operation. \n# Do not modify unless authorized.`;
      } else if (file.endsWith(".txt")) {
        return `Content of ${file}: \nThis text file contains important information and documentation relevant to system users.`;
      } else if (file.endsWith(".docx")) {
        return `Content of ${file}: \nThis document is prepared in DOCX format and includes formatted text and data. Use a compatible viewer to read this file properly.`;
      } else {
        return `No such file: ${file}`;
      }
    },

    decode: (args) => {
      if (!args || args.length === 0) {
        return "No token specified";
      }
      const token = args.trim();
      const decodedString = base64Decode(token);
      console.log(decodedString);
      if (
        decodedString ===
        `{"userId":"1","role":"admin","password":"plznohackme"}`
      ) {
        setDecodedToken(true);
        return `${decodedString}`;
      } else {
        return "Invalid token.";
      }
    },
    run: (args) => {
      if (!args || args.length === 0) {
        return "No script specified";
      }
      const parts = args.split(" "); // Split the input into parts
      const script = parts[0].trim();
      const password = parts[1] ? parts[1].trim() : ""; // Safely handle case where no password is provided

      if (script === "script.sh") {
        if (password === "plznohackme") {
          const updatedFiles = {
            ...files,
            "/home/user3/documents": [
              ...files["/home/user3/documents"],
              "flag.txt",
            ],
          };
          setFiles(updatedFiles);
          return "Password correct! Script executed. Now look for the flag file.";
        } else {
          return "Invalid or empty password.";
        }
      } else {
        return "Script not found.";
      }
    },
  };

  useEffect(() => {
    if (showVideo) {
      const int = setTimeout(() => {
        setShowVideo(false);
      }, 26000);
      return () => clearTimeout(int);
    }
  }, [showVideo]);

  useEffect(() => {
    return () => {
      setBufferedContent([]);
    };
  }, []);

  const handleMouseDown = (e) => {
    setDragDisabled(true);
  };

  const handleMouseUp = (e) => {
    setDragDisabled(false);
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {showVideo && (
        <YouTube
          videoId={videoShowing}
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              loop: 1,
              playlist: videoShowing,
            },
            height: 620,
            width: 800,
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            opacity: 0.8,
            pointerEvents: "none",
          }}
        />
      )}
      <div
        className="terminal-container"
        onMouseEnter={handleMouseDown}
        onMouseLeave={handleMouseUp}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <ReactTerminal
          showControlBar={false}
          themes={{
            "my-custom-theme": {
              themeBGColor: "#272B36",
              themeToolbarColor: "#DBDBDB",
              themeColor: "#FFFEFC",
              themePromptColor: "green",
            },
          }}
          theme="my-custom-theme"
          commands={commands}
          welcomeMessage={
            <span>
              Your mission is to find the password and unlock the flag. Use
              'ls', 'cd', 'cat', 'decode', and 'run' commands to navigate and
              solve the challenge.
              <br />
            </span>
          }
        />
      </div>
    </div>
  );
};

export default HackThisTerminalApplication;
