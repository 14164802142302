import React from "react";

const Paint = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src="https://jspaint.app/"
        title="Paint App"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Paint;
