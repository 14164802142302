import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";

const Chatbot = () => {
  const [messages, setMessages] = useState([
    {
      content: "Hiya! I am Jared, the AI chatbot. Ask me anything!",
      role: "assistant",
    },
  ]);
  const [inputText, setInputText] = useState("");
  const messagesEndRef = useRef(null);

  const handleSendMessage = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      if (inputText.trim().length === 0)
        return alert("Please enter a description.");

      setMessages((currentMessages) => [
        ...currentMessages,
        {
          content: inputText,
          role: "user",
        },
      ]);

      const uInput = inputText;

      setInputText("");

      const talkData = await fetch(
        "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/talkToBot",
        {
          method: "POST",
          body: JSON.stringify({
            messages: [
              ...messages,
              {
                role: "user",
                content: uInput,
              },
            ],
          }),
        }
      );

      const response = await talkData.json();

      setMessages((currentMessages) => [
        ...currentMessages,
        {
          content: response.chatResponse,
          role: "assistant",
        },
      ]);
    } else if (event.type === "click") {
      if (inputText.trim().length === 0)
        return alert("Please enter a description.");

      setMessages((currentMessages) => [
        ...currentMessages,
        {
          content: inputText,
          role: "user",
        },
      ]);

      const uInput = inputText;

      setInputText("");

      const talkData = await fetch(
        "https://us-central1-brandonportfolio-42f62.cloudfunctions.net/talkToBot",
        {
          method: "POST",
          body: JSON.stringify({
            messages: [
              ...messages,
              {
                role: "user",
                content: uInput,
              },
            ],
          }),
        }
      );

      const response = await talkData.json();

      setMessages((currentMessages) => [
        ...currentMessages,
        {
          content: response.chatResponse,
          role: "assistant",
        },
      ]);
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#444654",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "80%",
          width: "100%",
          overflowY: "scroll",
          paddingRight: 10,
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              textAlign: "left",
              backgroundColor: "#444654",
              width: "100%",
              minHeight: 90,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor:
                  message.role === "user" ? "#5D6AC0" : "#19C37D",
                minHeight: 50,
                minWidth: 50,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              {message.role === "user" ? "U" : "J"}
            </div>
            <div style={{ marginLeft: 20, color: "white" }}>
              {message.content}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "80%",
          height: 40,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <textarea
          onKeyDown={handleSendMessage}
          value={inputText}
          placeholder="Enter message here..."
          onChange={(event) => setInputText(event.target.value)}
          style={{
            width: "100%",
            height: 40,
            backgroundColor: "#40414F",
            borderRadius: 5,
            outline: "none",
            resize: "none",
            color: "white",
            padding: 6,
          }}
        />
        <div
          onClick={handleSendMessage}
          style={{
            height: 42,
            width: 42,
            backgroundColor: "#40414F",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid white",
            borderRadius: 5,
            marginLeft: 5,
            cursor: "pointer",
          }}
        >
          <AiOutlineSend />
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
