import React, { useState, useRef, useEffect } from "react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const AllVideos = (props) => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(4);
  const divRef = useRef(null);

  useEffect(() => {
    if (props.videos) {
      const sortedVideos = [...props.videos].sort(() => Math.random() - 0.5);
      setVideos(sortedVideos);
    }
  }, [props.videos]);

  const nextVideos = () => {
    if (currentIndex + displayCount < videos.length) {
      setCurrentIndex(currentIndex + displayCount);
    }
  };

  const prevVideos = () => {
    if (currentIndex - displayCount >= 0) {
      setCurrentIndex(currentIndex - displayCount);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      console.log(divRef.current.offsetWidth);
      if (divRef.current.offsetWidth < 1100) {
        setDisplayCount(2);
      } else {
        setDisplayCount(4);
      }
    }
  }, [divRef.current?.offsetWidth]);

  return (
    <div
      ref={divRef}
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 40,
      }}
    >
      <div
        style={{
          color: "white",
          fontWeight: "bold",
          marginBottom: 10,
          alignSelf: "flex-start",
        }}
      >
        Videos
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%) translateX(-40px)",
            zIndex: 1,
          }}
        >
          <FaArrowCircleLeft
            onClick={prevVideos}
            style={{ color: "white", fontSize: 24, cursor: "pointer" }}
          />
        </div>
        {videos
          .slice(currentIndex, currentIndex + displayCount)
          .map((video) => (
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                margin: 8,
              }}
            >
              <iframe
                src={`https://www.youtube.com/embed/${video}`}
                title={video.title}
                frameBorder="0"
                allowFullScreen
                scrolling="no"
                style={{ height: 300, width: "100%" }}
              ></iframe>
            </div>
          ))}
        <div
          style={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%) translateX(40px)",
            zIndex: 1,
          }}
        >
          <FaArrowCircleRight
            onClick={nextVideos}
            style={{ color: "white", fontSize: 24, cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AllVideos;
