import React from "react";
import { animated, useSpring } from "react-spring";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import { IoMdMenu } from "react-icons/io";
import {
  FaChevronDown,
  FaChevronUp,
  FaCloudRain,
  FaCog,
  FaPowerOff,
} from "react-icons/fa";

const applicationDataArray = [
  {
    name: "About Me",
    id: 1,
    value: "Welcome to About Me",
    image: "portfolio.png",
  },
  {
    name: "Terminal",
    id: 2,
    value: "Welcome to the terminal application... type help to get started!",
    image: "terminal.png",
  },
  {
    name: "AI Chatbot",
    id: 3,
    value: "AI CHATBOT",
    image: "chatbot.png",
  },
  {
    name: "Browser",
    id: 96,
    value: "Browser",
    image: "browserIcon.png",
  },
  {
    name: "Paint",
    id: 39,
    value: "Paint",
    image: "paint.png",
  },
  {
    name: "AI Image Generator",
    id: 334,
    value: "AI Image Generator",
    image: "aiimage.png",
  },
  {
    name: "My Computer",
    id: 4,
    value: "My Computer",
    image: "pc.png",
  },
  {
    name: "Youtube",
    id: 444,
    value: "Youtube",
    image: "youtube.png",
  },
  {
    name: "Messenger",
    id: 22,
    value: "Messenger",
    image: "gmail.png",
  },
  {
    name: "Hack This Terminal",
    id: 7337,
    value: "Hack This Terminal",
    image: "hack.png",
  },
  {
    name: "Personal Projects",
    id: 5,
    value: "Personal Projects",
    image: "ui-design.png",
  },
  {
    name: "GTA Classic",
    id: 6,
    value: "GTA Classic",
    image: "gta.png",
  },
  {
    name: "VSCode",
    id: 723,
    value: "VSCode",
    image: "vscode.png",
  },
  {
    name: "Space Cadet",
    id: 891,
    value: "Space Cadet",
    image: "spacecadet.png",
  },
  {
    name: "Doom",
    id: 12,
    value: "Doom",
    image: "doom.png",
  },
];

const seExperimentCode = `/*
The Double-Slit Experiment is a classic demonstration of the wave-particle duality of particles such as electrons and photons.
When particles pass through two slits and hit a screen, they create an interference pattern indicative of wave behavior,
even when they are sent through one at a time.

https://en.wikipedia.org/wiki/Double-slit_experiment read more here!

This simulation models the experiment by simulating particles passing through two slits and recording where they hit a screen.
The screen is represented as an array, and the particle hits are recorded and displayed as a bar chart.
The randomness in the particle's position simulates the wave interference pattern.
*/


const numParticles = 10000;
const numSlits = 2;
const screenResolution = 100;


const createScreen = (resolution) => new Array(resolution).fill(0);


const simulateParticle = (screen, slits, resolution) => {
  const slit = Math.floor(Math.random() * slits);
  const position = Math.floor(resolution / slits * slit + (Math.random() - 0.5) * resolution / slits);
  screen[Math.abs(position % resolution)]++;
  return screen;
};


const runSimulation = (numParticles, numSlits, screenResolution) => {
  const screen = createScreen(screenResolution);
  return Array.from({ length: numParticles }).reduce(
    (screen) => simulateParticle(screen, numSlits, screenResolution),
    screen
  );
};


const displayResult = (screen) => {
  console.log('Double-Slit Experiment Simulation');
  console.log('---------------------------------');
  console.log('Screen Pattern:');
  screen.forEach((value, index) => {
    const bar = '█'.repeat(value / 100);
    console.log(\`\${index}: \${bar}\`);
  });
};


const main = () => {
  const screen = runSimulation(numParticles, numSlits, screenResolution);
  displayResult(screen);
};


main();`;

const StartMenu = ({ startMenuActive, handleOpenApplication }) => {
  const [gamesFolderOpen, setGamesFolderOpen] = React.useState(false);
  const [scriptsFolderOpen, setScriptsFolderOpen] = React.useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = React.useState("menu");

  const { data, isLoading, errorMessage } = useOpenWeather({
    key: "418260355934fac833deff08790a06fc",
    lat: "28.5384",
    lon: "81.3789",
    lang: "en",
    unit: "imperial", // values are (metric, standard, imperial)
  });
  const animation = useSpring({
    opacity: startMenuActive ? 1 : 0,
    transform: startMenuActive ? `translateY(0)` : `translateY(100%)`,
  });

  return (
    <animated.div style={animation} className="start-menu">
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          padding: 6,
        }}
      >
        <div
          style={{
            width: "12%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            onClick={() => setSelectedSubmenu("menu")}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              if (selectedSubmenu !== "menu") {
                e.currentTarget.style.backgroundColor = "transparent";
              }
            }}
            style={{
              margin: 4,
              marginTop: 8,
              cursor: "pointer",
              padding: 4,
              borderRadius: 5,
              backgroundColor:
                selectedSubmenu === "menu"
                  ? "rgba(255, 255, 255, 0.2)"
                  : "transparent",
            }}
          >
            <IoMdMenu color="white" size={30} />
          </div>
          <div
            onClick={() => setSelectedSubmenu("weather")}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              if (selectedSubmenu !== "weather") {
                e.currentTarget.style.backgroundColor = "transparent";
              }
            }}
            style={{
              margin: 4,
              marginTop: 8,
              cursor: "pointer",
              padding: 4,
              borderRadius: 5,
              backgroundColor:
                selectedSubmenu === "weather"
                  ? "rgba(255, 255, 255, 0.2)"
                  : "transparent",
            }}
          >
            <FaCloudRain color="white" size={30} />
          </div>
          <div
            onClick={() => setSelectedSubmenu("settings")}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              if (selectedSubmenu !== "settings") {
                e.currentTarget.style.backgroundColor = "transparent";
              }
            }}
            style={{
              margin: 4,
              marginTop: 8,
              cursor: "pointer",
              padding: 4,
              borderRadius: 5,
              backgroundColor:
                selectedSubmenu === "settings"
                  ? "rgba(255, 255, 255, 0.2)"
                  : "transparent",
            }}
          >
            <FaCog color="white" size={30} />
          </div>
          <div
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
            style={{
              margin: 4,
              position: "absolute",
              bottom: 60,
              cursor: "pointer",
              padding: 4,
              borderRadius: 5,
            }}
          >
            <FaPowerOff
              onClick={() => window.location.reload()}
              color="white"
              size={30}
            />
          </div>
        </div>
        {selectedSubmenu === "menu" && (
          <div
            style={{
              width: "88%",
              paddingLeft: 10,
              height: "90%",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() => handleOpenApplication(applicationDataArray[0])}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="portfolio.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>About Me</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() => handleOpenApplication(applicationDataArray[1])}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="terminal.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Terminal</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 22)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="gmail.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Messenger</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 3)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="chatbot.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>AI Chatbot</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 444)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="youtube.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Youtube</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 5)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="ui-design.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>
                Personal Projects
              </div>
            </div>
            <div
              onClick={() => setGamesFolderOpen(!gamesFolderOpen)}
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="folder.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Games</div>
              <div style={{ marginLeft: "auto", marginRight: 20 }}>
                {gamesFolderOpen ? (
                  <FaChevronUp color="white" />
                ) : (
                  <FaChevronDown color="white" />
                )}
              </div>
            </div>

            {gamesFolderOpen && (
              <div
                style={{
                  minHeight: 40,
                  justifyContent: "center",
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  borderRadius: 5,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    minHeight: 40,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: 5,
                    width: "100%",
                  }}
                  onClick={() =>
                    handleOpenApplication(
                      applicationDataArray.find((app) => app.id === 6)
                    )
                  }
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(255, 255, 255, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                >
                  <img src="gta.png" style={{ width: 25, height: 25 }} />
                  <div style={{ marginLeft: 10, color: "white" }}>
                    GTA Classic
                  </div>
                </div>
                <div
                  style={{
                    minHeight: 40,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: 5,
                    width: "100%",
                  }}
                  onClick={() =>
                    handleOpenApplication(
                      applicationDataArray.find((app) => app.id === 12)
                    )
                  }
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(255, 255, 255, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                >
                  <img src="doom.png" style={{ width: 25, height: 25 }} />
                  <div style={{ marginLeft: 10, color: "white" }}>Doom</div>
                </div>
                <div
                  style={{
                    minHeight: 40,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: 5,
                    width: "100%",
                  }}
                  onClick={() =>
                    handleOpenApplication(
                      applicationDataArray.find((app) => app.id === 891)
                    )
                  }
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(255, 255, 255, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                >
                  <img src="spacecadet.png" style={{ width: 25, height: 25 }} />
                  <div style={{ marginLeft: 10, color: "white" }}>
                    Space Cadet
                  </div>
                </div>
              </div>
            )}

            <div
              onClick={() => setScriptsFolderOpen(!scriptsFolderOpen)}
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="folder.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Scripts</div>
              <div style={{ marginLeft: "auto", marginRight: 20 }}>
                {scriptsFolderOpen ? (
                  <FaChevronUp color="white" />
                ) : (
                  <FaChevronDown color="white" />
                )}
              </div>
            </div>

            {scriptsFolderOpen && (
              <div
                style={{
                  minHeight: 40,
                  justifyContent: "center",
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  borderRadius: 5,
                  paddingLeft: 10,
                }}
              >
                <div
                  style={{
                    minHeight: 40,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: 5,
                    width: "100%",
                  }}
                  onClick={() =>
                    handleOpenApplication(
                      applicationDataArray.find((app) => app.id === 723),
                      seExperimentCode
                    )
                  }
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      "rgba(255, 255, 255, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                >
                  <img src="js.png" style={{ width: 35, height: 35 }} />
                  <div style={{ marginLeft: 0, color: "white" }}>
                    DS Experiment
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() => handleOpenApplication(applicationDataArray[4])}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="paint.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Paint</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 7337)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="hack.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>
                Hack This Terminal
              </div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 334)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="aiimage.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>
                AI Image Generator
              </div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 723)
                )
              }
            >
              <img src="vscode.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>VSCode</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                borderRadius: 5,
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 96)
                )
              }
            >
              <img src="browserIcon.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>Browser</div>
            </div>
            <div
              style={{
                minHeight: 40,
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() =>
                handleOpenApplication(
                  applicationDataArray.find((app) => app.id === 4)
                )
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <img src="pc.png" style={{ width: 25, height: 25 }} />
              <div style={{ marginLeft: 10, color: "white" }}>My Computer</div>
            </div>
          </div>
        )}

        {selectedSubmenu === "weather" && (
          <div
            style={{
              width: "88%",
              paddingLeft: 10,
              height: "90%",
              overflowY: "auto",
              opacity: 0.8,
            }}
          >
            <ReactWeather
              isLoading={isLoading}
              errorMessage={errorMessage}
              data={data}
              lang="en"
              locationLabel="Orlando"
              unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
              showForecast
            />
          </div>
        )}
      </div>
    </animated.div>
  );
};

export default StartMenu;
