import React from "react";

const GrandTheftAuto = () => {
  return (
    <iframe
      src="https://bmsoftware.dev/gta.html"
      title="Doom"
      style={{ width: "100%", height: "100%" }}
    ></iframe>
  );
};

export default GrandTheftAuto;
