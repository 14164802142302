import React, { useEffect, useState } from "react";

const Projects = () => {
  const [imageToDisplay, setImageToDisplay] = useState();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (activeTab === 1) {
      const images = ["psp1.png", "psp2.png", "psp3.png"];

      setImageToDisplay(images[0]);
      let index = 0;

      const int = setInterval(() => {
        index = index + 1;
        if (index === images.length) {
          index = 0;
        }
        setImageToDisplay(images[index]);
      }, 4000);

      return () => {
        clearInterval(int);
      };
    }
  }, [activeTab]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        backgroundColor: "#202020",
        borderTop: "1px solid white",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid white",
          height: 40,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setActiveTab(1)}
          style={{
            margin: 4,
            borderRight: "1px solid white",
            paddingRight: 6,
            cursor: "pointer",
            color: activeTab === 1 ? "white" : "grey",
          }}
        >
          Police Scanner Pro
        </div>
        <div
          onClick={() => setActiveTab(2)}
          style={{
            margin: 4,
            borderRight: "1px solid white",
            paddingRight: 6,
            cursor: "pointer",
            color: activeTab === 2 ? "white" : "grey",
          }}
        >
          Tropics Tracker
        </div>
      </div>
      {activeTab === 1 && (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 20,
            }}
          >
            <img
              style={{
                width: 360,
                height: 624,
                borderRadius: 5,
                border: "1px solid white",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={imageToDisplay}
            />
          </div>

          <div
            style={{
              marginLeft: 40,
              border: "1px solid white",
              width: "44%",
              height: "94%",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "20%",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex", fontSize: 15 }}>
                Police Scanner Pro - USA. The most advanced police scanner app
                on iOS! Access live feeds, engage in state-wide chats, and enjoy
                ad-free experience with Police Scanner Pro!
              </div>
            </div>
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "50%",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                paddingTop: 6,
                fontSize: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  flexWrap: "wrap",
                }}
              >
                Welcome to the most advanced police scanner app on iOS! Our app
                puts thousands of live police, fire, and EMS scanner feeds right
                at your fingertips, ensuring you stay informed and ahead of the
                curve. From small towns to major metropolitan areas, our
                extensive database covers scanner feeds across the United
                States. Constantly updated, we ensure you're getting the latest
                and most accurate information.
              </div>
            </div>
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "15%",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                paddingTop: 6,
                fontSize: 14,
              }}
            >
              <div>Technologies Used:</div>
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  flexWrap: "wrap",
                }}
              >
                Javascript, React Native, NodeJS, Firebase, Google Maps API.
              </div>
            </div>
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "15%",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                paddingTop: 10,
                fontSize: 14,
              }}
            >
              <div>Project Link:</div>
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  flexWrap: "wrap",
                }}
              >
                <a
                  href="https://apps.apple.com/us/app/police-scanner-pro-usa/id1480194136"
                  target="_blank"
                  style={{ color: "#366CFF" }}
                >
                  Apple App Store
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 2 && (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 20,
            }}
          >
            <img
              style={{
                width: 624,
                borderRadius: 5,
                border: "1px solid white",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={"tt1.png"}
            />
            <img
              style={{
                width: 624,
                borderRadius: 5,
                border: "1px solid white",
                objectFit: "contain",
                objectPosition: "center",
                marginTop: 10,
              }}
              src={"tt2.png"}
            />
          </div>

          <div
            style={{
              marginLeft: 40,
              border: "1px solid white",
              width: "42%",
              height: "94%",
              borderRadius: 5,
              marginRight: 20,
            }}
          >
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "20%",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex", fontSize: 15 }}>
                TropicsTracker Web App.
              </div>
            </div>
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "40%",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                paddingTop: 6,
                fontSize: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  flexWrap: "wrap",
                }}
              >
                This is a project I built for fun because I enjoy tracking
                hurricanes. The site has a feature called StormWall that allows
                users to submit live video feeds when hurricanes are going on,
                and everyone can watch multiple live feeds together and chat.
                The site also features a storm tracker page as well as an
                advanced GFS radar that has features such as wind visualization
                and live lighting strike tracking.
              </div>
            </div>
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "25%",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                paddingTop: 6,
                fontSize: 14,
              }}
            >
              <div>Technologies Used:</div>
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  flexWrap: "wrap",
                }}
              >
                Javascript, React, Next.js, Nodejs, Firebase, Discord, Google
                Maps API, Mapbox GL JS, TailwindCss , and Express.
              </div>
            </div>
            <div
              style={{
                color: "white",
                borderBottom: "1px solid white",
                height: "15%",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-center",
                paddingTop: 10,
                fontSize: 14,
              }}
            >
              <div>Project Link:</div>
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  flexWrap: "wrap",
                }}
              >
                <a
                  href="https://tropicstracker.io"
                  target="_blank"
                  style={{ color: "#366CFF" }}
                >
                  TropicsTracker
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
