import React, { useState, useEffect } from "react";
import { ReactTerminal, TerminalContext } from "react-terminal";
import YouTube from "react-youtube";

const TerminalApplication = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [videoShowing, setVideoShowing] = useState("");
  const { setBufferedContent, setTemporaryContent } =
    React.useContext(TerminalContext);

  const commands = {
    help: "Look, I'm a terminal, not a therapist. What do you want from me?",
    commands: () => {
      return "Fine, here you go, a list of commands: 'help', 'aboutMe', 'whoMadeYou', 'fortune', 'dance', 'hackTheGibson', 'weather', 'joke', 'clear'. You're welcome, or something...";
    },
    aboutMe: "Just a humble terminal, running code and making quips.",
    whoMadeYou: "A programmer with too much free time, obviously.",
    fortune: "Error 404: Crystal ball not found.",
    weather:
      "Oh sure, let me just look out the window... Wait, I don't have windows. I'm a terminal.",

    joke: () => {
      const jokes = [
        "Why don't programmers like nature? It has too many bugs.",
        "Why do programmers always mix up Christmas and Halloween? Because Oct 31 == Dec 25.",
        "Why did the web developer walk out of the restaurant? Because of the table layout.",
        "How many programmers does it take to change a light bulb? None, that's a hardware problem.",
      ];
      return jokes[Math.floor(Math.random() * jokes.length)];
    },
    dance: () => {
      setVideoShowing("BiAwpYIkRmU");
      setShowVideo(true);

      setTimeout(() => {
        setShowVideo(false);
      }, 130000);
      return "Alright, let's dance!";
    },
    hackTheGibson: () => {
      setVideoShowing("Bmz67ErIRa4");
      setShowVideo(true);

      setTimeout(() => {
        setShowVideo(false);
      }, 120000);
      return "GIBSON HACK INITIATED...";
    },
  };

  useEffect(() => {
    return () => {
      setBufferedContent([]);
    };
  }, []);

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {showVideo && (
        <YouTube
          videoId={videoShowing}
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              loop: 1,
              playlist: videoShowing,
              start: videoShowing === "BiAwpYIkRmU" ? 18 : 0,
            },
            height: 620,
            width: 800,
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            opacity: 0.8,
            pointerEvents: "none",
          }}
        />
      )}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <ReactTerminal
          enableInput={!showVideo}
          showControlBar={false}
          themes={{
            "my-custom-theme": {
              themeBGColor: "#272B36",
              themeToolbarColor: "#DBDBDB",
              themeColor: "#FFFEFC",
              themePromptColor: "green",
            },
          }}
          theme="my-custom-theme"
          commands={commands}
          welcomeMessage={
            <span>
              Welcome to my terminal! Type 'commands' to see a list of commands.{" "}
              <br />
            </span>
          }
        />
      </div>
    </div>
  );
};

export default TerminalApplication;
